export default {
  apiKey: "AIzaSyB2Eld5u4ipmHxZSpl2Vofquo5zAUP478Y",
  authDomain: "bodrumboatsbyzar.firebaseapp.com",
  databaseURL:
    "https://bodrumboatsbyzar-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "bodrumboatsbyzar",
  storageBucket: "bodrumboatsbyzar.appspot.com",
  messagingSenderId: "584616758620",
  appId: "1:584616758620:web:81d29ee1562d6990c50599",
  measurementId: "G-2VQB9N4S9M",
};
