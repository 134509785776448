import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import en from "./locales/en.json";
import tr from "./locales/tr.json";
import fr from "./locales/fr.json";
import ru from "./locales/ru.json";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          menu: {
            home: "Home",
            boats: "Boats",
          },
          product: {
            viewDetails: "View Details",
            priceFrom: "from ",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
          },
          reservation: {
            bookingMessage: "Booking Request from Bodrum.boats",
            makeareservation: "Make a Reservation",
            startDate: "Start Date",
            endDate: "End Date",
            tourDate: "Tour Date",
            tourName: "Tour Name",
            tourType: "Tour Type",
            adults: "Adults",
            children: "Children",
            guests: "Guests",
            bookNow: "Book Now via Whatsapp!",
            ischarter: "Charter?",

            tourPrice: "Tour Price",
            person: "Person",
            addBasket: "Add to Basket",
            pleaseSelect: "Please Select",

            payWhatsapp: "Complete order by Whatsapp",
            payDeposit: "Pay Deposit via Credit Card",
            payAll: "Pay via Credit Card",
            deposit: "Deposit",
          },
          amenities: {
            boatDetails: "Boat Details",
            boatType: "Boat Type",
            lenght: "Lenght",
            numberofCabins: "Number of Cabins",
            engine: "Engine",
          },
          pictures: {
            viewPhotos: "View Photos",
          },
          topBar: {
            overview: "Overview",
            details: "Details",
            reservation: "Reservation",
            boatDetails: "Boat Details",
            boatPrices: "Boat Prices",
            contactUs: "Contact Us",
            share: "Share",
          },
          tour: {
            prices: "Prices",
          },
          checkout: {
            tourInformation: "Tour Information",
            tourType: "Tour Type",
            basicInformation: "Basic Information",
            billingInformation: "Billing Information",
            paymentInformation: "Payment Information",
            identity: "Identity Number",
            date: "Tour Date",
            name: "Name",
            surname: "Surname",
            contactName: "Name and Surname",
            email: "Email",
            address: "Address",
            country: "Country",
            city: "City",
            state: "State",
            zipCode: "Zip Code",
            pHcontactName: "Please enter your Name and Surname",
            pHname: "Please enter your Name ",
            pHdate: "Please select tour date ",
            pHsurname: "Please enter your Surname",
            pHemail: "Please enter email address",
            pHaddress: "Please enter your Address",
            pHcountry: "Please enter your Country",
            pHcity: "Please enter your City",
            pHzipCode: "Please enter your Zip Code",
            pHstate: "Please enter your State",
            pHIdentity: "Please enter your Identity Number",
            checkIdentity: "Please check your Identity Number",
            checkname: "Please check your Name",
            checkcontactName: "Please check your Name and Surname",
            checkaddress: "Please check your Address",
            checkcountry: "Please check your Country",
            checkcity: "Please check your City",
            checkstate: "Please check your State",
            saveButton: "Save Address and Continue",
            addacard: "Add a Card",
            cardNumber: "CARD NUMBER",

            checkCreditCard: "Please check your credit card number",
            checkExpiricy: "Please check your expiricy date",
            checkCvc: "Please check your cvc",
            checkName: "Please check your name and surname",
            checkEmail: "Please check your email address",
            checkZipCode: "Please check your Zipcode",
            continue: "Add Card and Continue",
            placeOrder: "Place Order",
            paymentMethod: "Payment Method",
            orderPlaced: "Order Placed",
            orderPlacedHeader: "Congratulations!",
            orderPlacedDescription: "Your order has been placed successfully.",
            next: "Continue",
            back: "Back",
            pleaseSelectService: "Please Select Your Service",
            orderSummary: "Order Summary",
            pay: "Pay",
            orderReceived: "Your Order has been placed",
            orderStatus: "Order Status",
          },
        },
      },
      tr: {
        translation: {
          product: {
            viewDetails: "Detayları Gör",
            priceFrom: "Başlangıç fiyatı: ",
            May: "Mayıs",
            June: "Haziran",
            July: "Temmuz",
            August: "Ağustos",
            September: "Eylül",
            October: "Ekim",
          },
          reservation: {
            bookingMessage: "Bodrum.boats'tan Rezervasyon Talebi",
            makeareservation: "Rezervasyon",
            startDate: "Başlangıç ​​tarihi",
            endDate: "Bitiş tarihi",
            tourDate: "Tur Tarihi",
            tourName: "Tur Adı",
            tourType: "Tur Türü",
            adults: "Yetişkinler",
            children: "Çocuklar",
            guests: "Misafirler",
            bookNow: "Whatsapp ile Rezervasyon Yapın!",
            person: "Kişi",
            addBasket: "Sepete Ekleyin",
            pleaseSelect: "Lütfen Seçiniz",
            payWhatsapp: "Siparişi Whatsapp ile tamamla",
            payDeposit: "Kredi Kartı ile Depozito öde",
            payAll: "Kredi Kartı ile Tamamını öde",
            deposit: "Depozito",
          },
          amenities: {
            boatDetails: "Tekne Detayları",
            boatType: "Tekne Tipi",
            lenght: "Uzunluk",
            numberofCabins: "Kabin Sayısı",
            engine: "Motor Gücü",
          },
          pictures: {
            viewPhotos: "Fotoğrafları Gör",
          },
          topBar: {
            overview: "Genel",
            boatDetails: "Tekne Detayları",
            boatPrices: "Tekne Fiyatları",
            contactUs: "Bize Ulaşın",
            share: "Paylaş",
          },
          checkout: {
            cardHolder: "Kart Sahibi",
            cardNumber: "Kart Numarası",
            expires: "Son Kullanma",
            month: "Ay",
            year: "Yıl",
            fullName: "ADI SOYADI",
            tourInformation: "Tur Bilgileri",
            tourType: "Tur Tipi",
            basicInformation: "Genel Bilgileri",
            billingInformation: "Fatura Bilgileri",
            paymentInformation: "Ödeme Bilgileri",
            identity: "Kimlik Numarası",
            date: "Tur Tarihi",
            name: "Adı",
            surname: "Soyadı",
            contactName: "Adı ve Soyadı",
            email: "Email",
            address: "Adres",
            country: "Ülke",
            city: "Şehir",
            state: "State",
            zipCode: "Posta Kodu",
            pHcontactName: "Lütfen Ad ve Soyadınızı girin",
            pHname: "Lütfen Adınızı Girin ",
            pHdate: "Lütfen Tur Tarihini Seçin ",
            pHsurname: "Lütfen Soyadınızı Girin",
            pHemail: "Lütfen Email Adresinizi Girin",
            pHaddress: "Lütfen Adresinizi Girin",
            pHcountry: "Lütfen Ülkenizi Girin",
            pHcity: "Lütfen Şehrinizi Girin",
            pHzipCode: "Lütfen Posta Kodumnuzu Girin",
            pHstate: "Lütfen State Girin",
            pHIdentity: "Lütfen Kimlik Numaranızı Girin",
            checkIdentity: "Lütfen Kimlik Numaranızı Kontrol Edin",
            checkname: "Lütfen Adınızı Kontrol Edin",
            checkcontactName: "Lütfen Ad ve Soyadınızı Kontrol Edin",
            checkaddress: "Lütfen Adresinizi Kontrol Edin",
            checkcountry: "Lütfen Ülkenizi Kontrol Edin",
            checkcity: "Lütfen Şehrinizi Kontrol Edin",
            checkstate: "Lütfen State Kontrol Edin",
            checkZipCode: "Lütfen Posta Kodunuzu Kontrol Edin",
            saveButton: "Adresi Kaydet ve Detam Et",
            addacard: "Kart Ekleyin",
            cardNumber: "KART NUMARASI",

            checkCreditCard: "Lütfen Kredi Kartı Numaranızı Kontrol Edin",
            checkExpiricy: "Lütfen Son Kullanma Tarihinizi Kontrol Edin",
            checkCvc: "Lütfen CVC Kontrol Edin",

            checkEmail: "Lütfen Email Adresinizi Kontrol Edin",
            continue: "Kartı Kaydet ve Detam Et",
            placeOrder: "Sipariş Oluştur",
            paymentMethod: "Ödeme Yöntemi",
            orderPlaced: "Sipariş Oluşturuldu",
            orderPlacedHeader: "Tebrikler!",
            orderPlacedDescription: "Siparişiniz Başarı ile Oluşturuldu.",
            next: "Devam et",
            back: "Geri",
            pleaseSelectService: "Lütfen Servisi Seçiniz",
            orderSummary: "Sipariş Özeti",
            pay: "Öde",
            orderReceived: "Siparişiniz Oluşturuldu",
            orderStatus: "sipariş Durumu",
          },
          tour: {
            prices: "Fiyatlar",
          },
        },
      },
      fr: {
        translation: {
          pictures: {
            viewPhotos: "View Photos",
          },
          topBar: {
            overview: "Overview",
            boatDetails: "Boat Details",
            boatPrices: "Boat Prices",
            contactUs: "Contact Us",
            share: "Share",
          },
        },
      },
      ru: {
        translation: {
          pictures: {
            viewPhotos: "View Photos",
          },
          topBar: {
            overview: "Overview",
            boatDetails: "Boat Details",
            boatPrices: "Boat Prices",
            contactUs: "Contact Us",
            share: "Share",
          },
        },
      },
    },
  });

export default i18n;
