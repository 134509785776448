/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import moment from "moment";

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  AGENT_PROFILE_PAGE,
  PRICING_PLAN_PAGE,
} from "settings/constant";
const lngs = {
  en: { nativeName: "English", flag: "/images/flagen.png" },
  tr: { nativeName: "Türkçe", flag: "/images/flagtr.png" },
  fr: { nativeName: "Français", flag: "/images/flagfr.png" },
  ru: { nativeName: "Русский", flag: "/images/flagru.png" },
  ar: { nativeName: "عربي", flag: "/images/flagar.png" },
};
const MainMenu = ({ className }) => {
  const { t, i18n } = useTranslation();
  return (
    <Menu className={className}>
      {/* <Menu.Item key="0">
        <NavLink exact to={`${HOME_PAGE}`}>
          {t("menu.home")}
        </NavLink>
      </Menu.Item>
      <Menu.Item key="1">
        <NavLink to={`${LISTING_POSTS_PAGE}`}>{t("menu.boats")}</NavLink>
      </Menu.Item> */}
      {Object.keys(lngs).map((lng, index) => (
        <Menu.Item key={"menu-" + index}>
          <a
            key={lng}
            href={"#"}
            style={{
              fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
              padding: "0px",
            }}
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              i18n.changeLanguage(lng);
              moment.locale(lng);
            }}
          >
            <img
              src={lngs[lng].flag}
              style={{ width: "30px" }}
              alt={lngs[lng].nativeName}
            />
            {/* {lngs[lng].nativeName} */}
          </a>
        </Menu.Item>
      ))}

      {/* <Menu.Item key="2">
        <NavLink to={`${AGENT_PROFILE_PAGE}`}>Agency</NavLink>
      </Menu.Item>
      <Menu.Item key="3">
        <NavLink to={`${PRICING_PLAN_PAGE}`}>Pricing</NavLink>
      </Menu.Item> */}
    </Menu>
  );
};

export default MainMenu;
